import { useAuth } from "@Context/Auth.context";
import { SubjectBanner } from "LocaleBased/SubjectBanner";
import useTranslation from "next-translate/useTranslation";
import FilledStarSvgIcon from "public/svgs/icons/global-icons/FilledStarSvgIcon";
import WatchPercentageCircle from "public/svgs/icons/global-icons/WatchPercentageCircle";
import { Fragment, useState } from "react";
import { AlgoliaPackageSubject } from "types/AlgoliaSearch";
import { Subject } from "types/subject";
import {
  Ellipse,
  Info,
  Instructor,
  Price,
  PriceAfterDiscount,
  SubjectBottomContent,
  SubjectContentBg,
  SubjectContentPlaceHolder,
  SubjectContentWrapper,
  SubjectInner,
  SubjectTopContent,
  Title,
  University,
  WatchedPercentage,
  WatchedPercentageWrapper,
} from "./PackageCard.styled";

type Props = {
  data: Subject | AlgoliaPackageSubject;
  isAlgoliaPackage?: boolean;
  isPackagePage?: boolean;
};

const PackageCard = ({ data, isAlgoliaPackage, isPackagePage }: Props) => {
  const { t } = useTranslation("common");
  const [loadImageError, setLoadImageError] = useState(false);
  const hasDiscount = data?.pricing?.has_discount && data?.pricing.discount_rate > 0;
  const { localIpInfo } = useAuth();

  return (
    <SubjectContentWrapper
      isLightTheme={data?.is_light_theme}
      isAlgoliaPackage={isAlgoliaPackage}
      href={`/subjects/${data?.slug}`}
    >
      {data && (
        <Fragment>
          {!loadImageError ? (
            <SubjectContentBg
              src={data?.image?.url || ""}
              alt={isAlgoliaPackage ? (data as AlgoliaPackageSubject)["name"].ar : (data as Subject).name}
              onError={() => setLoadImageError(true)}
              layout="fill"
              loading="lazy"
            />
          ) : (
            <SubjectContentPlaceHolder />
          )}
          <SubjectInner isAlgoliaPackage={isAlgoliaPackage}>
            <SubjectTopContent>
              {Boolean((data as Subject).label) && (
                <SubjectBanner
                  countryCode={localIpInfo?.country_code}
                  style={{ color: data.is_light_theme ? (data as Subject).color : "inherit" }}
                >
                  {(data as Subject).label}
                </SubjectBanner>
              )}
              {isAlgoliaPackage ? (
                <Title isAlgoliaPackage={isAlgoliaPackage}>{(data as AlgoliaPackageSubject)["name"].ar}</Title>
              ) : (
                <Title>{(data as Subject).name}</Title>
              )}
              <Instructor isAlgoliaPackage={isAlgoliaPackage}>{data.instructor?.name}</Instructor>
            </SubjectTopContent>
            {data.category && (
              <University isAlgoliaPackage={isAlgoliaPackage}>
                {isAlgoliaPackage
                  ? (data as AlgoliaPackageSubject).category["name"].ar
                  : (data as Subject).category.name}
              </University>
            )}
            <SubjectBottomContent>
              {data.rating > 0 && (
                <Fragment>
                  <FilledStarSvgIcon
                    color={data.is_light_theme ? "white" : "black"}
                    width={isAlgoliaPackage ? "6" : "14"}
                    height={isAlgoliaPackage ? "6" : "14"}
                    margin="0 5px"
                  />
                  <Info isAlgoliaPackage={isAlgoliaPackage}>{data.rating}/5</Info>
                  <Ellipse isAlgoliaPackage={isAlgoliaPackage} />
                </Fragment>
              )}
              {!isAlgoliaPackage && !isPackagePage && (data as Subject).is_subscribed ? (
                <WatchedPercentageWrapper>
                  <WatchedPercentage>
                    <WatchPercentageCircle percentage={(data as Subject).watched_percentage} />
                  </WatchedPercentage>
                  {(data as Subject).watched_percentage}%
                </WatchedPercentageWrapper>
              ) : data.courses_count > 1 ? (
                <Info isAlgoliaPackage={isAlgoliaPackage}>
                  {data.courses_count} {t("courses_lowercase")}
                </Info>
              ) : hasDiscount ? (
                <>
                  <Price isSlashed={true} isAlgoliaPackage={isAlgoliaPackage}>
                    {(data as Subject).pricing.undiscounted_price} {(data as Subject).pricing.currency}
                  </Price>
                  <PriceAfterDiscount isAlgoliaPackage={isAlgoliaPackage}>
                    {Number((data as Subject).pricing.price) > 0
                      ? `${(data as Subject).pricing.price} ${(data as Subject).pricing.currency}`
                      : t("free")}
                  </PriceAfterDiscount>
                </>
              ) : (
                <PriceAfterDiscount isAlgoliaPackage={isAlgoliaPackage}>
                  {Number((data as Subject).pricing?.price) > 0
                    ? `${(data as Subject).pricing.price} ${(data as Subject).pricing.currency}`
                    : t("free")}
                </PriceAfterDiscount>
              )}
            </SubjectBottomContent>
          </SubjectInner>
        </Fragment>
      )}
    </SubjectContentWrapper>
  );
};

export default PackageCard;
