import { Fragment } from "react";

type Props = {
  strokeCircle?: boolean;
  percentage?: number;
};

const WatchPercentageCircle = ({ strokeCircle, percentage }: Props) => {
  return (
    <Fragment>
      {strokeCircle ? (
        <svg viewBox="0 0 36 36" className="circular-chart orange">
          <path
            className="circle-bg dark"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle dark"
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 36 36" className="circular-chart orange">
          <path
            className="circle-bg"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      )}
    </Fragment>
  );
};

export default WatchPercentageCircle;
