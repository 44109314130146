import { Fragment } from "react";
import { AlgoliaPackageSubject } from "types/AlgoliaSearch";
import { Subject } from "types/subject";
import PackageCard from "./PackageCard";
import SubjectCard from "./SubjectCard";

type Props = {
  data?: Subject | AlgoliaPackageSubject;
  isAlgoliaPackage?: boolean;
  isPackagePage?: boolean;
  packageLayout?: boolean;
};

export default function SubjectCardContent({ data, isAlgoliaPackage, isPackagePage, packageLayout }: Props) {
  return (
    <Fragment>
      {packageLayout ? (
        <PackageCard data={data} isAlgoliaPackage={isAlgoliaPackage} isPackagePage={isPackagePage} />
      ) : (
        <SubjectCard data={data} />
      )}
    </Fragment>
  );
}
