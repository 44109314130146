type Props = {
  color: string;
  width?: string;
  height?: string;
};

const CompletedMark = ({ color, width, height }: Props) => {
  return (
    <svg
      width={width ? width : "18"}
      height={height ? height : "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={color} />
      <path
        d="M8.04557 12.8917C8.24002 12.8917 8.39345 12.8066 8.50586 12.6365L12.8125 5.85522C12.852 5.79142 12.8809 5.7337 12.8991 5.68205C12.9173 5.62736 12.9264 5.57267 12.9264 5.51799C12.9264 5.39342 12.8885 5.29316 12.8125 5.2172C12.7365 5.14125 12.6363 5.10327 12.5117 5.10327C12.4206 5.10327 12.3461 5.1215 12.2884 5.15796C12.2307 5.19138 12.1745 5.25062 12.1198 5.33569L8.02734 11.8572L5.90365 9.07723C5.79427 8.92836 5.66363 8.85392 5.51172 8.85392C5.38411 8.85392 5.2793 8.89494 5.19727 8.97697C5.11523 9.05596 5.07422 9.15774 5.07422 9.28231C5.07422 9.337 5.08485 9.3932 5.10612 9.45093C5.12739 9.50562 5.16081 9.5603 5.20638 9.61499L7.57161 12.6274C7.70833 12.8036 7.86632 12.8917 8.04557 12.8917Z"
        fill="white"
      />
    </svg>
  );
};

export default CompletedMark;
