import { useAuth } from "@Context/Auth.context";
import theme from "@Style/theme";
import dayjs from "dayjs";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import CompletedMark from "public/svgs/icons/global-icons/CompletedMark";
import FilledStarSvgIcon from "public/svgs/icons/global-icons/FilledStarSvgIcon";
import WatchPercentageCircle from "public/svgs/icons/global-icons/WatchPercentageCircle";
import { Fragment, useState } from "react";
import { AlgoliaPackageSubject } from "types/AlgoliaSearch";
import { Subject } from "types/subject";
import { PriceAfterDiscount, WatchedPercentage } from "../PackageCard/PackageCard.styled";
import {
  CardTagsWrapper,
  CollageName,
  CreatedAtTag,
  SubjectCardContainer,
  SubjectCardImage,
  SubjectCardImagePlaceholder,
  SubjectCardImageWrapper,
  SubjectCardInfoWrapper,
  SubjectCoursesNumber,
  SubjectDetailsWrapper,
  SubjectLabel,
  SubjectName,
  SubjectRating,
  SubjectRatingWrapper,
  SubjectWatchProgressWrapper,
  TeacherName,
  WatchedPercentageNumber,
} from "./SubjectCard.styled";

type Props = {
  data: Subject | AlgoliaPackageSubject;
};

const SubjectCard = ({ data }: Props) => {
  const { t } = useTranslation("common");
  const [loadImageError, setLoadImageError] = useState(false);
  const { localIpInfo } = useAuth();
  const { asPath } = useRouter();
  const currentDate = dayjs();
  const createdAt = dayjs((data as Subject)?.created_at);
  const isAddedRecently = currentDate.diff(createdAt, "day") <= 30;

  return (
    <Fragment>
      {data && (
        <SubjectCardContainer href={`/subjects/${data.slug}`}>
          <SubjectCardImageWrapper>
            {!loadImageError ? (
              <SubjectCardImage
                src={(data as Subject).image?.url || ""}
                alt={(data as Subject).name}
                onError={() => setLoadImageError(true)}
                width={262}
                height={196}
                loading="lazy"
              />
            ) : (
              <SubjectCardImagePlaceholder />
            )}
            {!(data as Subject).is_subscribed ? (
              <CardTagsWrapper>
                {(data as Subject).rating > 0 && (
                  <SubjectRatingWrapper>
                    <FilledStarSvgIcon color="#1a1a1a" width="12" height="12" />
                    <SubjectRating>{(data as Subject).rating}</SubjectRating>
                  </SubjectRatingWrapper>
                )}
                {isAddedRecently && <CreatedAtTag>{t("added_recently")}</CreatedAtTag>}
                {(data as Subject).label && <SubjectLabel>{(data as Subject).label}</SubjectLabel>}
              </CardTagsWrapper>
            ) : (
              <CardTagsWrapper>
                {data?.is_subscribed &&
                  ((data as Subject).watched_percentage === 0 || !(data as Subject).watched_percentage) && (
                    <SubjectLabel>{t("subscribed")}</SubjectLabel>
                  )}
                {(data as Subject).watched_percentage && (
                  <SubjectWatchProgressWrapper>
                    {(data as Subject).watched_percentage === 100 ? (
                      <CompletedMark color={theme.colors.green} width="16" height="16" />
                    ) : (
                      <WatchedPercentage smallCircle>
                        <WatchPercentageCircle percentage={(data as Subject).watched_percentage} />
                      </WatchedPercentage>
                    )}
                    <WatchedPercentageNumber>{(data as Subject).watched_percentage}%</WatchedPercentageNumber>
                  </SubjectWatchProgressWrapper>
                )}
              </CardTagsWrapper>
            )}
            <SubjectDetailsWrapper isLightTheme={(data as Subject).is_light_theme}>
              {(data as Subject).courses_count > 0 && (
                <SubjectCoursesNumber>
                  {(data as Subject).courses_count} {t("courses_lowercase")}
                </SubjectCoursesNumber>
              )}
              <PriceAfterDiscount>
                {!data?.is_subscribed && (data as Subject)?.pricing?.type === "free" && t("free")}
              </PriceAfterDiscount>
              {/* {(data as Subject)?.pricing?.type === "free" && asPath !== "/" && (
                <PriceAfterDiscount>{data?.is_subscribed ? t("subscribed") : t("free")}</PriceAfterDiscount>
              )} */}
            </SubjectDetailsWrapper>
          </SubjectCardImageWrapper>
          <SubjectCardInfoWrapper>
            {(data as Subject).category?.name && <CollageName>{(data as Subject).category?.name}</CollageName>}
            <SubjectName>{(data as Subject).name}</SubjectName>
            <TeacherName>{(data as Subject).instructor?.name}</TeacherName>
          </SubjectCardInfoWrapper>
        </SubjectCardContainer>
      )}
    </Fragment>
  );
};

export default SubjectCard;
